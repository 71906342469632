import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import { BlogItem, BlogPostData, MAIN_ID_BLOG, readingTime } from "../blogUtils"
import { Layout } from "../components/Layout"
import { ContactSection } from "../commonComponents"
import HubspotForm from "react-hubspot-form"
import { Helmet } from "react-helmet"

export default function Blog({ data }) {
  const blogsAll: BlogPostData[] = data.allContentfulBlogPost.nodes
  const blog = blogsAll.find(blog => blog.slug === MAIN_ID_BLOG)
  const keyArticlesList = blogsAll.slice(0, 3) || []
  const blogs = blogsAll.filter(
    b1 => !keyArticlesList.some(b2 => b1.slug === b2.slug)
  )

  const KeyArticles: Function = (): JSX.Element[] => {
    return keyArticlesList?.map((currentBlog, index) => (
      <Link
        to={`/blog/${currentBlog.slug}/`}
        className="blog-container-key"
        style={{
          borderBottom: index === 1 ? "1px solid #C8C8C8" : "",
          borderTop: index === 1 ? "1px solid #C8C8C8" : "",
        }}
      >
        <div className="blog-key-image">
          <img
            src={currentBlog.heroImage.file.url}
            alt="blogImage"
            style={{
              width: "100%",
              height: "11vh",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="blog-key-container-info">
          <div className="blog-key-title">{currentBlog.title}</div>
          <br />
          <div className="blog-key-date">
            {currentBlog.createdAt} | {readingTime(currentBlog.blogContent.raw)}{" "}
            Mins read
          </div>
          <div className="blog-key-date">{currentBlog.createdAt}</div>
        </div>
      </Link>
    ))
  }

  return (
    <Layout
      keywords="proactive AI customer support, automate support tickets, customer support news"
      title="Real-Time Ticket Contextualization: Customer Support With Proactive AI"
      description="Enhance Customer Support with Real-Time Ticket contextualization & proactive AI solutions for swift issue resolution & exceptional assistance. Click here to learn more!"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
      </Helmet>
      <div style={{ marginTop: "5%", overflowX: "hidden" }}>
        <section className="section-padding-left section-padding-right first-section-padding-top">
          <div className="row">
            <Link
              to={`/blog/${blog.slug}/`}
              className="col-md-6 blog-key-margin"
            >
              <img
                src={blog.heroImage.file.url}
                alt="blogImage"
                style={{
                  maxWidth: "92%",
                }}
              />
              <div className="blog-main-date">
                {blog.createdAt} | {readingTime(blog.blogContent.raw)}{" "}
                Mins read
              </div>
              <div className="blog-main-title">{blog.title}</div>
            </Link>
            <div
              className="col-md-6 blog-key-margin"
              style={{ marginTop: "2%" }}
            >
              <h1 className="blog-key-articles">Latest Articles</h1>
              <KeyArticles />
            </div>
          </div>
        </section>
        <div
          className="row"
          style={{ backgroundColor: "#333333", paddingBottom: "2%" }}
        >
          <div className="col-md-6">
            <div className="blog-subscribe-margin">
              <div className="mt-4 blog-subscribe-title">
                Subscribe to our blog
              </div>
              <HubspotForm
                portalId="7494256"
                formId="6a1d4af4-75c3-4ada-83be-49b69f32a4f4"
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
          <div className="col-md-6 ">
            <img
              className="blog-card-button-icon blog-subscribe-image web-view-new-design-display"
              src={"/img/blogSubscribe.png"}
              alt="arrow"
              style={{
                width: "50%",
                marginLeft: "20%",
              }}
            />
          </div>
        </div>

        <div className="mb-5" style={{ marginTop: "5%", marginLeft: "8%", marginRight: "8%" }}>
          <div className="row">
            {blogs.map(blog => (
              <BlogItem blog={blog} key={blog.slug} theme={"light"} />
            ))}
          </div>
        </div>
      </div>
      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}

export const query = graphql`
  query Blogs {
    allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
      nodes {
        slug
        title
        createdAt(formatString: "MMM DD, YYYY")
        heroImage {
          file {
            url
          }
          title
        }
        summary {
          summary
        }
        tags
        blogContent {
          raw
        }
      }
    }
  }
`
